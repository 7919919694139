//min-width
$breakpoint-up: (
  'xs': 'screen and (min-width: 576px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1200px)',
  'xxl': 'screen and (min-width: 1500px)',
) !default;

// max-width
$breakpoint-down: (
  'xs': 'screen and (max-width: 575px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1199px)',
  'xxl': 'screen and (max-width: 1499px)',
) !default;

// @mixinの定義
@mixin mq-up($breakpoint: xs) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}
@mixin mq-down($breakpoint: xs) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

//色
$dft: #333333;
$wht: #F2F2F2;
$red: #880000;
$red_bright: #9c0000;
$pnk: #cd8585;

//フォント
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
@import url('https://fonts.googleapis.com/earlyaccess/notosansjp.css');

$font-jp:"游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-mincho: "游明朝",YuMincho,"ＭＳ Ｐ明朝","MS PMincho",serif;
$font-notosansjp: 'Noto Sans JP', sans-serif;

//余白
$mp-dft : 60px;
$mp-dft_sp : 40px;

//
@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '…';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}

