@charset "utf-8";
@import "setting";

body {
	font-size: 16px;
	letter-spacing: 1.5px;
	line-height: 1.8;
	color: #595757;
	font-family: $font-notosansjp;
	@include mq-down(lg) {
		font-size: 15px;
		letter-spacing: 1.0px;
		line-height: 1.6;
	}
	@include mq-down() {
		font-size: 14px;
		line-height: 1.6;
	}
}
a {
	transition: all .4s ease-out;
	color: #595757;
	img {
		transition: all .4s ease-out;
	}
	&:hover, &:focus {
		text-decoration: none;
		img {
			opacity: 0.6;
		}
	}
}
::selection{
color:#fff;
background:#333;
}
::-moz-selection{
color:#fff;
background:#333;
}

section {
	margin: $mp-dft auto;
	@include mq-down(xs) {
		margin: $mp-dft_sp auto;
	}
	&.colored {
		margin: 0 auto;
		padding: $mp-dft 0;
		@include mq-down(xs) {
			padding: $mp-dft_sp 0;
		}
	}
	&.no-pm {
		padding: 0;
		margin: 0;
	}
}
.nb span {
    white-space: nowrap!important;
    display: inline-block!important;
}
.taC_L {
	text-align: center!important;
	@include mq-down(md) {
		text-align: justify!important;
	}
}
.taC_L_s {
	text-align: center!important;
	@include mq-down(xs) {
		text-align: justify!important;
	}
}

//フォントサイズ
h1,h2,h3,h4,h5 {
  line-height: 1.5;

}
h1 {
  font-size: 36px;
  @include mq-down(md) {
    font-size: 28px;
  }
}
h2 {
	font-size: 40px;
	font-weight: bold;
	color: #FFF;
	margin: 0 0 20px 0;
	line-height: 1.5;
	padding-bottom: 0;
	@include mq-down(lg) {
		font-size: 32px;
	}
  @include mq-down(md) {
		font-size: 19px;
		margin: 0 0 15px 0;
	}
	&.h2_pnk {
		margin-top: 30px;
		text-align: center;
		@include mq-down(sm) {
			margin-top: 10px;
		}
	}
}
h3 {
	color: #FFFFBD;
	font-size: 26px;
	font-weight: bold;
	margin: 30px 0 20px 0;
	@include mq-down(lg) {
		font-size: 24px;
	}
  @include mq-down(md) {
		font-size: 16px;
		margin: 23px 0 12px 0;
  }
}

p {
  font-size: 15px;
  margin-bottom: 30px;
  text-align: justify;
	@include mq-down(xs) {
		font-size: 14px;
		margin-bottom: 10px;
	}
}

ul {
	margin: 0;
	padding: 0;
}

.fixed {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
	margin-top:0vh;
}
//ヘッダースタイル
header {
	background: $wht;
	box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.4);
	position: relative;
	z-index: 99;
	width: 100%;
	@include mq-down(sm) {
		background: $wht;
		box-shadow: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
	}
	.subnav_pc {
		justify-content: space-between;
		align-items: center;
		padding: 18px 15px 13px 15px;
		.inner {
			display: block;
			.sub {
				display: flex;
				justify-content: flex-end;
				li {
					display: inline-block;
					border-left: 1px solid $dft;
					padding: 0 10px 0 20px;
					position: relative;
					line-height: 1;
					&::before {
						position:absolute;
						top: 50%;
						left: 8px;
						display: inline-block;
						transform: translateY(-50%);
						content: url('../img/h_list.png');
					}
					a {
						color: $dft;
						font-size: 14px;
						&:hover, &:focus {
							color: $dft;
							text-decoration: underline;
						}
						@include mq-down(md) {
							font-size: 14px;
						}
					}
				}
			}
			.sub02 {
				margin-top: 25px;
				display: flex;
				justify-content: flex-end;
				align-items:flex-start;
				li {
					display: inline-block;
					list-style-type: none;
					.red-bdr_contact {
						border: 2px solid $red;
						color: $red;
						font-weight: bold;
						display: flex;
						align-items: center;
						padding: 10px 15px;
						margin-left: 20px;
						font-size: 16px;
						@include mq-down(md) {
							font-size: 14px;
						}
						img {
							margin-left: 7px;
							align-self: flex-start;
						}
						&:hover, &:focus {
							opacity: .6;
						}
					}
				}
			}
		}
	}
	.navbar {
		width: 100%;
		@include mq-down(sm) {
			padding: 8px;
		}
	}
	.navbar-brand {
		width: 220px;
	}
	.navbar-nav {
		width: 100%;
		margin: 3px auto;
		@include mq-down(sm) {
			margin: 25px auto;
		}
		.nav-item {
			border-left: 1px solid #fff;
			@include mq-down(sm) {
				border-left: 0;
				display: block;
				flex-basis: auto;
			}
			a {
				line-height: 1.5;
				color :#fff;
				font-weight: bold;
				text-align: center;
				font-size: 18px;
				padding-top: 0;
				padding-bottom: 0;
				opacity: 1;
				@include mq-down(md) {
					font-size: 16px;
				}
				@include mq-down(sm) {
					font-size: 18px;
					letter-spacing: 2px;
					padding: 8px;
				}
				span {
					display: block;
					color: $pnk;
					font-weight: normal;
					font-size: 12px;
					@include mq-down(md) {
						font-size: 9px;
					}
					@include mq-down(sm) {
						font-size: 10px;
					}
				}
				&:hover, &:focus {
					opacity: .6;
				}
			}
			.nav-link {
				@include mq-down(md) {
					padding-right: .4rem!important;
					padding-left: .4rem!important;
				}
			}
		}
	}
	.navbar-toggler {
		border: 3px solid $red_bright;
		padding: 6px;
		border-radius: 0;
		width:56px;
		height:56px;
			.btn_menu{
				display: block;
				font-size: 12px;
				font-weight: normal;
				color: #000;
			}
			.btn_close{
				display: none;
			}
			.bar{
				display: block;
				height: 3px;
				border-radius: 5;
				background-color: $red_bright;
				margin: 3px 0 6px 0;
				width: 38px;
				transition: all 0.2s;
				transform-origin: 0 0;
			}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(156,0,0,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
		&.btn-on {
			.btn_menu{
				display: none;
			}
			.btn_close{
				display: block;
				font-size: 12px;
				letter-spacing:0;
				font-weight: normal;
				color: #000!important;
			}
			.bar {
				width: 42px;

			}
			.bar1 {
				transform: rotate(26deg);
				transform-origin:top left;
			}
			.bar2 {
				opacity: 0;
			}
			.bar3 {
				transform: rotate(-26deg);
				transform-origin:bottom left;
			}
		}
	}
	@include mq-down(sm) {
		.navbar-collapse {
			background: $red;
			margin: 8px -15px -15px -15px;
		}
	}
	.red-sec {
		background: $red;
		@include mq-down(sm) {
			background: white;
		}
		.container {
			@include mq-down(sm) {
				width: 100%;
				max-width: 100%;
			}
		}
	}
}

//フッタースタイル
footer {
	.footer_pc {
		background: #b54b4b;
		color: #FFF;
		padding: 30px 0;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.f_box {
				display: flex;
				align-items: flex-end;
				p {
					font-size: 12px;
					line-height: 1.4;
					margin: 5px 0;
					color: #fff;
				}
				@include mq-down(md) {
					display: block;
				}
				.f_box_info{
					img {
						margin-bottom:15px!important;
					}
					@include mq-down(md) {
						img {
							height: 50px;
						}
					}
				}
			}
			.sub {
				list-style-type: none;
				margin-top: 30px;
				li {
					display: inline-block;
					margin: 0 5px;
					font-size: 13px;
					a {
						color: white;
						&:hover, &:focus {
							text-decoration: underline;
						}
					}
				}
				@include mq-down(lg) {
					display: none;
				}
			}
		}
	}
	.footer_sp {
		background: #fff;
		color: #000;
		nav{
			ul {
				background: #eeeeee;
				text-align: center;
				list-style-type: none;
				li {
					a {
						display:block;
						color: #000;
						font-weight: bold;
						padding: 12px;
						border-bottom: 1px solid #9a9a9a;
						&:hover,&:focus {
							opacity: .6;
						}
					}
				}
			}
		}
		.footer-logo {
			width: 220px;
			height: 113px;
		}
		.footer_info {
			padding: 0px 15px;
			.footer-logo {
				margin: 40px auto 20px auto;
				display: block;
			}
			ul {
				list-style-type: none;
				li {
					border-top: 1px solid #000;
					text-align: center;
					padding: 12px 0;
					font-size: 13px;
					font-weight: bold;
					a {
						color: #000;
						font-size: 22px;
						font-weight: 900;
						&:hover,&:focus {
							opacity: .6;
						}
					}
					&:last-of-type {
						border-bottom: 1px solid #000;
					}
				}
			}
			.footer_truck {
				width: 120px;
				display: block;
				margin: 30px auto 2px auto;
			}
		}
		#pageTop.pagetop_sp {
			position: fixed;
			bottom: 35px;
			right: 15px;
			a {
				width: 40px;
				height: 40px;
				background: #000;
				display: block;
				z-index: 999;
				position: relative;
				span.arrow {
					position: absolute;
					display: block;
					width: 15px;
					height:15px;
					border: 2px solid transparent;
					border-left-color: white;
					border-top-color: white;
					top: 17px;
					left: 13px;
					transform: rotate(45deg);
				}
			}
		}
		small {
			background: #000;
			display: block;
			width: 100%;
			color: white;
			text-align: center;
			padding: 5px;
			font-size: 12px;
		}
	}
}
//ページトップ
.pagetop {
	margin-bottom: 0;
	@include mq-down(sm) {
		margin-top: 20px;
	}
	a {
		color: white;
		&:hover,&:focus {
			text-decoration: underline;
		}
	}
		text-align: right;
}

//共通スタイル
#eyecatch {
	text-align: center;
	height: 400px;
	@include mq-down(md) {
		height: 320px;
	}
	@include mq-down(sm) {
		height: 260px;
	}
	@include mq-down(xs) {
		height: 185px;
	}
}
.eyecatch-text_cnt {
	width: 100%;
	height: 100vh;
	position: relative;
	img {
		max-width: 80%;
	}
}
.eyecatch-text-sp {
	img {
		height: 110px;
	}
}
.eyecatch_cnt {
	width: 100%;
	position: relative;
	@include mq-down(sm){
		margin-top: 72px;
	}
}
.eyecatch_cnt img {
		max-width: 90%;
}
.eyecatch-late,.eyecatch-text,.top-eyecatch-text {
	display:none
}
@include mq-down(sm) {
	.eyecatch-late {
		display: block;
	}
	.eyecatch-text-contact {
		display: none;
	}
}

.top-eyecatch-text-first, .eyecatch-text-first{
	@include mq-down(sm) {
		display: none;
	}
}
.top-eyecatch-sp, .eyecatch-text-sp {
	display: none;
	@include mq-down(sm) {
		display: block;
		margin-bottom: 0;
	}
}


.eyecatch-text-first, .eyecatch-text,.top-eyecatch-text-first, .top-eyecatch-text, .top-eyecatch-sp,.eyecatch-text-sp,.eyecatch-text-contact {
		position:absolute;
		top:50%;
		transform: translateY(-50%);
		left: 0;
		z-index:9999;
		width:100%;
		text-align:center
}

//ボタンスタイル

//背景スタイル
.bg-rd {
	background: url(../img/main_bg.png) top left repeat;
	color: white;
}

.bg-wht {
	background: #FFF;
	color: #4C4C4C;
	.pagetop a {
		color: $red;
	}
}

.bg-gray {
	background: #f2f2f2;
}
//動くトラック
.movingtruck {
	right:0;
	position:absolute;
	z-index:1;
	margin-bottom: 70px;
	width: 10%;
	margin-right: 32%;
	bottom: 0px;

	animation-name: anime1;
	animation-duration: 5s;
	animation-timing-function: ease;
	animation-iteration-count: 1;
	animation-delay: -1s;

	@media only screen and (max-width: 1400px) {
		margin-right: 28%;

		animation-name: anime2;
		animation-duration: 5s;
		animation-timing-function: ease;
		animation-iteration-count: 1;
		animation-delay: -1s;
	}
	@include mq-down(lg) {
		margin-right: 33%;

		animation-name: anime3;
		animation-duration: 5s;
		animation-timing-function: ease;
		animation-iteration-count: 1;
		animation-delay: -1s;
	}
	@include mq-down(md) {
		margin-bottom: 63px;
		margin-right: 39%;

		animation-name: anime4;
		animation-duration: 5s;
		animation-timing-function: ease;
		animation-iteration-count: 1;
		animation-delay: -1s;
		img {
			height: 60px;
		}
	}
	@include mq-down(sm) {
		display: none;
	}
}

@keyframes anime1 {
0% {width: 0%;margin-right: 0%;}
100% {width: 10%;margin-right: 32%;}
}

@keyframes anime2 {
0% {width: 0%;margin-right: 0%;}
100% {width: 10%;margin-right: 28%;}
}
@keyframes anime3 {
0% {width: 0%;margin-right: 0%;}
100% {width: 10%;margin-right: 33%;}
}

@keyframes anime4 {
0% {width: 0%;margin-right: 0%;}
100% {width: 10%;margin-right: 39%;}
}

